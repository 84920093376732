import './sktv.css';
import React, {useState, useEffect} from 'react';
import {Document, Page} from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import useWindowDimensions from "./useWindowDimensions";

function Sktv() {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        'pdfjs-dist/build/pdf.worker.min.js',
        import.meta.url,
    ).toString();

    const { windowWidth } = useWindowDimensions();

    const pageChangeInterval = 5_000;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [timeoutSchedule] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        console.log('onDocumentLoadSuccess', document, document.numPages);

        setNumPages(numPages);
        setPageNumber(1);
    }

    if (numPages) {
        schedulePageChange();
        // setTimeoutSchedule(schedulePageChange());
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        clearTimeout(timeoutSchedule);
        changePage(-1);
    }

    function nextPage() {
        clearTimeout(timeoutSchedule);
        changePage(1);
    }

    function schedulePageChange() {
        return setTimeout(() => {
            console.log('page change', pageNumber, numPages);

            if (!numPages || pageNumber < numPages) {
                setPageNumber(pageNumber + 1);
            } else {
                setPageNumber(1);
            }

        }, pageChangeInterval);
    }

    useEffect(() => {
        function handleKeyDown(e) {
            // console.log(e.keyCode);
            if (e.keyCode === 37 || e.keyCode === 65) {
                previousPage();
            } else if (e.keyCode === 39 || e.keyCode === 68) {
                nextPage();
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        // Don't forget to clean up
        return function cleanup() {
            clearTimeout(timeoutSchedule);
            document.removeEventListener('keydown', handleKeyDown);
        }
    });

    return (
        <>

            <Document
                className={'sktv-div crt vignette'}
                file='kepujsag.pdf'
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <p>Page {pageNumber} of {numPages}</p>

                <Page
                    pageNumber={pageNumber}
                    width={windowWidth > 1200 ? windowWidth / 2 : 400}
                />
            </Document>

        </>
    );
}

Sktv.propTypes = {};

export default Sktv;
