import Snowfall from "react-snowfall";
import "./App.css";
import Sktv from "./sktv/sktv";

function App() {
    const currentMonth = new Date().getMonth();

    // const previousPage = () => {
    //     return (
    //         <div className="text-center text-white bg-image">
    //             <Toaster position="top-right"/>
    //
    //             <div className="cover-container h-100 d-flex w-100 mx-auto flex-column">
    //                 {birthDayPicture !== null ? renderBirthday() : <Gossip/>}
    //             </div>
    //
    //             {(currentMonth > 10 || currentMonth < 3) && (<Snowfall/>)}
    //         </div>
    //     );
    // }

    const renderSktv = () => {
        return (
            <div className="text-center text-white bg-image-tv">

                <div className="cover-container h-100 d-flex w-100 mx-auto flex-column align-items-center">
                    <Sktv />
                </div>

                {(currentMonth > 10 || currentMonth < 3) && (<Snowfall/>)}
            </div>
        );
    }

    return (
        // previousPage()
        renderSktv()
    );
}

export default App;
